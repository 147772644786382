.selected-row {
  background-color: #b7e4ff !important;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

#container {
  height: 100%;
  flex-direction: column;
}